import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, FormGroup, FormInput, Button, FormFeedback } from "shards-react";
import { handleForgotPassword, handleLogin } from "../redux/actions/auth";
import { useInput } from "../hooks/useInput";
import { yupErrorReducers } from "../utils/helpers";
import { useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";
import { forgotPasswordSchema } from "../validations/forgotPasswordValidations";

const ForgotPassword = () => {
  const { loading: loadingState } = useSelector(state => state.loading);
  const dispatch = useDispatch();
  const [emailProps, resetEmail] = useInput("");

  const [errors, setError] = useState({});

  const handleSubmit = async e => {
    e.preventDefault();
    setError({});
    const email = emailProps.value;
    forgotPasswordSchema
      .validate({ email }, { abortEarly: false })
      .then(formData => {
        console.log(formData);
        dispatch(handleForgotPassword(formData))
      })
      .catch(error => {
        const errs = yupErrorReducers(error.inner);
        setError(errs);
      });
  };

  return (
    <React.Fragment>
      <Loading loading={loadingState} background="#b3b1b380" loaderColor="#e73d05" />
      <div className="login-card">
        <Form className="login-form" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <div className="row justify-content-center align-items-center py-2 pb-4">
                <img src={require("../images/tingtel/tingtel-logo.png")} width="40" alt={"Tingtel logo"} />
                <div className="px-2 h5 pb-0 mb-0">Tingtel Dashboard</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <FormGroup>
                <label htmlFor="feEmailAddress">Email</label>
                <FormInput placeholder="Enter Email" invalid={!!errors.email} {...emailProps} />
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup className="mb-0 mt-3">
            <Button theme="accent" type="submit" className="btn-block">
              Submit
            </Button>
          </FormGroup>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
