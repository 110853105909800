import * as yup from 'yup'

export const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email().required('Email is required')
})


export const resetPasswordSchema = yup.object().shape({
    newPassword: yup.string().required('New Password is required'),
    confirmPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match')
})







// newPassword: "password@1"
// resetToken: "Q2ZESjhCSHI5Y1BBUFVaS3R3bGc1UXAyTktXUENvNllYSTIyYnlLZWlKYXQyNlk3bGVlZUZudUhtblJISndGTDZkOTk5OUxadm10UzlhTG9IUDFocWxtN3FZODdvNDc4TlgxczhVeFgwYUJ0UHhzQ1JWZlBqS3E4ejc1djF3MGFLcEsraXBTZ2xaZm5nN0o4YTZzcXErVnNoSmtBTTl3QXZzaHFQeGlZZmFKeTJ5aVhHNFZsQldvQUdQTFFWcjQ1TVVtRWRBPT0"
// userId: "14"