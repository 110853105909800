import React from "react";

const EmptyList = ({ message }) => {
  return (
    <div className="empty-list">
      <div className="empty-list-img">
        <img src={require("../../images/empty-state-2.png")} />
      </div>
      <p>No Results Found!</p>
    </div>
  );
};

export default EmptyList;
