import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, EmptyLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Login from "./views/Login";
import ChangePassword from "./views/ChangePassword";
import Dashboard from "./views/Dashboard";
import RouteGuard from "./components/guard/RouteGuard";
import AuthGuard from "./components/guard/AuthGuard";
import Transactions from "./views/Transactions";
import Wallets from "./views/Wallets";
import Airtimes from "./views/Airtimes";
import AccountProfile from './views/AccountProfile'
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/Resetpassword";

export default [
  {
    path: "/",
    exact: true,
    layout: EmptyLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/login",
    exact: true,
    layout: EmptyLayout,
    component: Login
  },
  {
    path: "/change-password",
    exact: true,
    layout: EmptyLayout,
    component: () => {
      return (
        <AuthGuard>
          <ChangePassword />
        </AuthGuard>
      );
    }
  },
  {
    path: "/dashboard",
    exact: true,
    layout: DefaultLayout,
    component: () => {
      return (
        <RouteGuard>
          <Dashboard />
        </RouteGuard>
      );
    }
  },
  {
    path: "/transactions",
    exact: true,
    layout: DefaultLayout,
    component: () => (
      <RouteGuard>
        <Transactions />
      </RouteGuard>
    )
  },
  {
    path: "/wallets",
    layout: DefaultLayout,
    component: () => (
      <RouteGuard>
        <Wallets />
      </RouteGuard>
    )
  },
  {
    path: "/airtimes",
    layout: DefaultLayout,
    component: () => (
      <RouteGuard>
        <Airtimes />
      </RouteGuard>
    )
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/account-profile",
    layout: DefaultLayout,
    //  component: ComponentsOverview,
    component: AccountProfile
  },
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: () => (
      <RouteGuard>
        <UserProfileLite />
      </RouteGuard>
    )
  },
  {
    path: "/forgot-password",
    exact: true,
    layout: EmptyLayout,
    component: ForgotPassword
  },
  {
    path: "/reset-password",
    exact: true,
    layout: EmptyLayout,
    component: ResetPassword
  }

];
