import { backOffice } from "../../axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { loading } from "./loading";

export const SET_TOKEN = "SET_TOKEN";

export const SET_CHANGE_PASSWORD = "SET_CHANGE_PASSWORD";

const login = currentUser => ({
  type: SET_TOKEN,
  currentUser
});

export function handleLogin(data, history) {
  const roles = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
  return dispatch => {
    dispatch(loading(true));
    backOffice
      .post("/identity/login", data)
      .then(response => {
        dispatch(loading(false));

        const token = response.data.data.token;
        const { PartnerId: partnerId, RequirePasswordReset } = jwt_decode(token);
        const passwordResetRequired = JSON.parse(RequirePasswordReset.toLowerCase());
        const partnerRole = jwt_decode(token)[roles].includes("Partners");

        dispatch(login({ ...response.data.data, partnerId }));

        if (!partnerRole) {
          throw new Error("Account is not registered as a partner, Conctat Admistrator!");
        }
        localStorage.setItem("user", JSON.stringify({ ...response.data.data, partnerId }));

        if (passwordResetRequired) {
          history.push("/change-password");
        } else {
          history.push("/dashboard");
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(loading(false));
        toast.error(error.response ? error.response.data.message : error.message, {
          position: toast.POSITION.TOP_CENTER
        });
      });
  };
}

export function handleChangePassword(data, history) {
  return dispatch => {
    dispatch(loading(true));
    backOffice
      .post("/identity/changepassword", data)
      .then(() => {
        dispatch(loading(false));
        history.push("/login");
        toast.success("Password changed Successfully!. Please Login with your new password", {
          position: toast.POSITION.TOP_CENTER
        });
      })
      .catch(error => {
        dispatch(loading(false));
        toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
      });
  };
}


export function handleForgotPassword(data) {
  return dispatch => {
    dispatch(loading(true));
    backOffice
    .post("/identity/initiatePasswordReset", {...data, dashboardIdentifier: 2})
    .then((response) => {
      dispatch(loading(false));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    })
    .catch(error => {
      dispatch(loading(false));
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_CENTER });
    });
  }
}


export function handleResetPassword(data, history) {
  return dispatch => {
    dispatch(loading(true));
    backOffice
    .post("/identity/completepasswordReset", data)
    .then((response) => {
      dispatch(loading(false));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
     history.push('/login')
    })
    .catch(error => {
      dispatch(loading(false));
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_CENTER });
    });
  }
}


export function logout(history) {
  return () => {
    localStorage.clear();
    history.push("/login");
  };
}
