export const colors = [
  {
    backgroundColor: "rgba(0,123,255,0.1)",
    borderColor: "rgba(0,123,255,1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(0,123,255)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(255,65,105,0.1)",
    borderColor: "rgba(255,65,105,1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(255,65,105)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(232,178,42,0.1)",
    borderColor: "rgba(232,178,42,1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(232,178,42,1)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(191,8,8, 0.1)",
    borderColor: "rgba(191,8,8, 1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(0,123,255)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(181,26,171,0.1)",
    borderColor: "rgba(181,26,171,1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(0,123,255)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(9, 139, 148, 0.1)",
    borderColor: "rgba(9, 139, 148, 1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(0,123,255)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(68, 59, 59,0.1)",
    borderColor: "rgba(68, 59, 59,1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(0,123,255)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(0,123,255,0.1)",
    borderColor: "rgba(0,123,255,1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(0,123,255)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(0,123,255,0.1)",
    borderColor: "rgba(0,123,255,1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(0,123,255)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(0,123,255,0.1)",
    borderColor: "rgba(0,123,255,1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(0,123,255)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  },
  {
    backgroundColor: "rgba(0,123,255,0.1)",
    borderColor: "rgba(0,123,255,1)",
    pointBackgroundColor: "#ffffff",
    pointHoverBackgroundColor: "rgb(0,123,255)",
    borderWidth: 1.5,
    pointRadius: 0,
    pointHoverRadius: 3
  }
  
];
