import { SET_PROFILE } from "../actions/profile";




export default function profileReducer(state = {}, action) {
    switch (action.type) {
      case SET_PROFILE:
        return {...state, ...action.profile}
      default:
        return state;
    }
  }