import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";

import Chart from "../../utils/chart";

class UsersByDevice extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {}

  render() {
    const { title } = this.props;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="d-flex py-0 px-0">
          <table class="table">
            <thead>
              <tr style={{textAlign: 'center'}}>
                <th scope="col">Product</th>
                <th scope="col">Cost</th>
                <th scope="col">Count</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{textAlign: 'center'}}>
               
                <td>MTN</td>
                <td>383,847</td>
                <td>374765</td>
              </tr>
              <tr style={{textAlign: 'center'}}>
                
                <td>Airtel</td>
                <td>23,934</td>
                <td>383,009</td>
              </tr>
              <tr style={{textAlign: 'center'}}>
              
                <td>GLO</td>
                <td>384,808</td>
                <td>387484</td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </Card>
    );
  }
}

UsersByDevice.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

UsersByDevice.defaultProps = {
  title: "Top Transactions",
  chartData: {
    datasets: [
      {
        hoverBorderColor: "#ffffff",
        data: [68.3, 24.2, 7.5],
        backgroundColor: [
          "rgba(0,123,255,0.9)",
          "rgba(0,123,255,0.5)",
          "rgba(0,123,255,0.3)"
        ]
      }
    ],
    labels: ["Desktop", "Tablet", "Mobile"]
  }
};

export default UsersByDevice;
