import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, ListGroup, ListGroupItem, Row, Col, Form, FormInput } from "shards-react";
import { handleFetchProfile } from "../../redux/actions/profile";

const AccountDetails = ({ title }) => {

  const dispatch = useDispatch()
  const profile = useSelector(state => state.profile)


  useEffect(() => {
   dispatch(handleFetchProfile())
  }, [])

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col xs="12" sm="9">
              <Form>
                <Row className="mb-3">
                  <Col xs="12" sm="6" className="d-flex align-items-center">
                    <label htmlFor="feEmailAddress">Business Name</label>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormInput value={profile.name} disabled />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs="12" sm="6" className="d-flex align-items-center">
                    <label htmlFor="feEmailAddress">Email Address</label>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormInput value={profile.email} disabled />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs="12" sm="6" className="d-flex align-items-center">
                    <label htmlFor="feEmailAddress">Phone Number</label>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormInput value={profile.phoneNumber} disabled />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs="12" sm="6" className="d-flex align-items-center">
                    <label htmlFor="feEmailAddress">Address</label>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormInput value={profile.address} disabled />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs="12" sm="6" className="d-flex align-items-center">
                    <label htmlFor="feEmailAddress">Account Type</label>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormInput value={profile.accountType} disabled />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
};
export default AccountDetails;
