import { SET_TOKEN } from "../actions/auth";


 const currentUser = JSON.parse(localStorage.getItem('user'))

const initialState = {
  currentUser
};


export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, currentUser: action.currentUser };
    default:
      return state;
  }
}
