import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardFooter, CardHeader, Row } from "shards-react";
import ReactPaginate from "react-paginate";
import EmptyList from "../components/Tables/EmptyList";

const TableList = ({
  headers,
  title,
  data,
  renderItem,
  fetchPageRecords,
  totalRecords,
  pageSize = 10
}) => {
  const { loading } = useSelector(state => state.loading);
  const [page, setPage] = useState(1)

  const hasBeenShown = (page, pageSize, totalRecords) => {
    const shownItem  = pageSize * page
    if(shownItem > totalRecords) return totalRecords
    return shownItem
  }

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">{title}</CardHeader>
      <CardBody>
        {data.length && !loading ? (
          <table className="table mb-0 table-responsive">
            <thead className="bg-light">
              <tr>
                {headers.map((header, i) => (
                  <th key={i} scope="col" className="border-0">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>{renderItem(item, i)}</tr>
              ))}
            </tbody>
          </table>
        ) : loading ? (
          <h5 className="text-center">Loading...</h5>
        ) : (
          <EmptyList />
        )}
      </CardBody>
      {(totalRecords > pageSize ) ? (
        <CardFooter className="border-top">
          <div className="paginate-container">
            <p className="pb-0 mb-0">Showing {hasBeenShown(page, pageSize, totalRecords)} of {totalRecords} Records</p>
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(totalRecords / pageSize)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={({ selected }) => {
                setPage(selected + 1)
                fetchPageRecords(selected + 1)
              }}
              containerClassName={"pagination"}
              activeClassName={"paginate-active"}
            />
          </div>
        </CardFooter>
      ) : null}
    </Card>
  );
};

export default TableList;
