import React from "react";
import { useSelector } from "react-redux";
import { naira } from "../../utils/helpers";
import TableList from "../../views/TableList";

const headers = ["No.", "Status", "Count", "Value"];


const renderRow = (item, i) => {
  return (
    <>
      <td>{i + 1}</td>
      <td>{item.status}</td>
      <td>{item.count}</td>
      <td>{naira(item.value)}</td>
    </>
  );
};

const TransactionSummaryTable = () => {
  const { summaries } = useSelector(state => state.dashboard);

  const data = [
    {
      status: "Success",
      count: summaries.totalSuccessCount,
      value: summaries.totalSuccessAmount
    },
    {
      status: "Fail",
      count: summaries.totalFailedCount,
      value: summaries.totalFailedAmount
    }
  ];
  return (
    <TableList
      headers={headers}
      title={<h6 className="m-0">Transaction Status</h6>}
      data={data}
      renderItem={renderRow}
    />
  );
};

export default TransactionSummaryTable;
 