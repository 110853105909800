import axios from "axios";
import { logout } from "../redux/actions/auth";
import store from "../store";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({});

const { dispatch } = store;

const UNAUHTORIZED = 401;

const buildClientRequest = url => {
  const http = axios.create({
    baseURL: url,
    headers: {
      "content-type": "application/json",
      accept: "application/json"
    }
  });

  http.interceptors.request.use(function(config) {
    const token = JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user")).token;
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  http.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === UNAUHTORIZED) {
        dispatch(logout(history));
        //Todo find a cleaner way redirect instead of the window object
        return (window.location.href = "/");
      }
      return Promise.reject(error);
    }
  );
  return http;
};

const backOffice = buildClientRequest(`${process.env.REACT_APP_BACKOFFICE_API}`);
const clientApi = buildClientRequest(`${process.env.REACT_APP_CLIENT_API}`);

export { backOffice, clientApi };
