
export const SET_LOADING = "SET_LOADING";


export const loading = payload => ({
    type: SET_LOADING,
    payload
})



