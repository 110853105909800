import * as yup from 'yup'

export const buyAirtimeSchema = yup.object().shape({
    recipientPhoneNumber: yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(11, 'Must be exactly 11 digits')
    .max(11, 'Must be exactly 11 digits'),
    amount: yup.number().typeError('Amount field must be a number').required().positive().integer()
})

