import React, { useEffect, useState } from "react";
import { Container, Row, Col, FormSelect, Button } from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import UsersOverview from "../components/custom/ProductOverview";
import RangeDatePicker from "../components/common/RangeDatePicker";
import ProductSummaryTable from "../components/dashboard-summaries/ProductSummayTable";
import TransactionSummaryTable from "../components/dashboard-summaries/TransactionSummaryTable";
import { useDispatch } from "react-redux";
import { handleDashboardData, setDashboardEndDate, setDashboardStartDate } from "../redux/actions/dashboard";
import { useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";
import { dateYmd } from "../utils/helpers";
import { dashboardRangeFilters } from "../utils/dashboar-range-filters";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { loading: loadingState } = useSelector(state => state.loading);
  const { startDate, endDate, summaries, timeSeriesData } = useSelector(state => state.dashboard);
  const [range, setRange] = useState("last-week");

  useEffect(() => {
    dispatch(handleDashboardData());
  }, []);

  useEffect(() => {
    setRange(range);
    dashboardRangeFilters(range, dispatch);
  }, [range]);

  const handleStartDate = value => {
    dispatch(setDashboardStartDate(dateYmd(value)));
  };

  const handleEndDate = value => {
    dispatch(setDashboardEndDate(dateYmd(value)));
  };

  const handleDashboardFilters = () => {
    dispatch(handleDashboardData());
  };


  return (
    <Container fluid className="main-content-container px-4">
      <Loading loading={loadingState} background="#b3b1b380" loaderColor="#e73d05" />
      <Row noGutters className="page-header py-4">
        <Col lg="3" md="3" sm="12">
          <PageTitle title="Overview" subtitle="Dashboard" className="text-sm-left mb-3" />
        </Col>
        <Col lg="8" md="8" sm="12" className="d-flex align-items-center justify-content-end">
          <FormSelect
            size="md"
            value={range}
            style={{ maxWidth: "130px" }}
            onChange={evt => setRange(evt.target.value)}
          >
            <option value="today">Today</option>
            <option value="last-week">Last 7 days</option>
            <option value="last-month">Last 30 days</option>
          </FormSelect>
          <RangeDatePicker
            key={startDate || endDate}
            startDateValue={handleStartDate}
            endDateValue={handleEndDate}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            size="md"
            className="w-auto"
          />
        </Col>
        <Col lg="1" md="1" sm="12" className="d-flex align-items-center justify-content-end">
          <Button onClick={handleDashboardFilters} theme="accent" type="submit" className="btn-sm-block">
            Filter
          </Button>
        </Col>
      </Row>

      {/* Small Stats Blocks */}
      <div className="stats-container">
        <div className="stat">
          <p>Transactions</p>
          <span>{summaries.totalTransactionCount}</span>
        </div>
        <div className="stat">
          <p>Success</p>
          <span>{summaries.totalSuccessCount}</span>
        </div>
        <div className="stat">
          <p>Failed</p>
          <span>{summaries.totalFailedCount}</span>
        </div>
        <div className="stat">
          <p>Success Rate %</p>
          <span>{summaries.successRatePercentage}</span>
        </div>
      </div>

      <Row>
        <Col lg="12" md="12" sm="12" className="mb-4">
          <UsersOverview chartData={timeSeriesData} />
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" sm="12" className="mb-4">
          <ProductSummaryTable />
        </Col>
        <Col lg="6" md="6" sm="12" className="mb-4">
          <TransactionSummaryTable />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
