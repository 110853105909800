import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, ButtonGroup } from "shards-react";
import AirtimeListTable from "../components/Tables/AirtimeListTable";
import { handleFetchAirtimeTransactions } from "../redux/actions/airtimes";
import { Button } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import Loading from "react-fullscreen-loading";
import BuyAirtimePage from "../components/partials/BuyAirtime";
import { handleFetchWallet } from "../redux/actions/wallet";
import CsvDownload from "../components/partials/CsvDownload";
import { airtimeCsvHeaders } from "../utils/csv-headers";
const Airtimes = () => {
  const dispatch = useDispatch();
  const { partnerId } = useSelector(state => state.auth.currentUser);
  const { loading: loadingState } = useSelector(state => state.loading);
  const { result } = useSelector(state => state.airtimes);

  const [openBuyAirtimeModal, setOpenBuyAirtimeModal] = useState(false);

  useEffect(() => {
    dispatch(
      handleFetchAirtimeTransactions({
        partnerId,
        currentPage: 1
      })
    );
    dispatch(handleFetchWallet());
  }, []);

  const closeAirtimeModal = () => setOpenBuyAirtimeModal(false);

  return (
    <Container fluid className="main-content-container px-4">
      <Loading loading={loadingState} background="#b3b1b380" loaderColor="#e73d05" />
      <BuyAirtimePage openModal={openBuyAirtimeModal} closeButton={closeAirtimeModal} />
      <Row noGutters className="page-header py-4">
        <Col xs="6">
          <PageTitle title="Airtime" subtitle="Overview" className="text-sm-left mb-3 text-left" />
        </Col>
        <Col xs="6" className="d-flex align-items-center justify-content-end">

          <Button sm="3" size="md" theme="primary" onClick={() => setOpenBuyAirtimeModal(true)}>
            Purchase Airtime
          </Button>
          <Button sm="3" className="ml-2">
            <CsvDownload csvData={{data:result, headers:airtimeCsvHeaders, filename:"airtime-transactions.csv"}} />
          </Button>

        </Col>
      </Row>
      <Row>
        <Col sm="12" className="mb-4">
          <AirtimeListTable />
        </Col>
      </Row>
    </Container>
  );
};

export default Airtimes;
