import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "shards-react";
import { connect } from "react-redux";
// import Chart from "chart.js";
import Chart from "../../utils/chart";

class ProductOverview extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    console.log("componentDidMount");
    console.log(this.props.chartData);

    // const ConversionChart = new Chart(this.canvasRef.current, {
    //   type: "LineWithLine",
    //   data: this.props.chartData
    // });
    //
    // try {
    //   const buoMeta = ConversionChart.getDatasetMeta(0);
    //   buoMeta.data[0]._model.radius = 0;
    //   buoMeta.data[this.props.chartData.datasets[0].data.length - 1]._model.radius = 0;
    // } catch (e) {
    // }
    //
    // // Render the chart.
    // ConversionChart.render();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
    console.log(this.props.chartData);

    // const currentCanvas = this.canvasRef.current;
    // const context = currentCanvas.getContext('2d');
    // context.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
    // console.log("currentCanvasWidth: " + currentCanvas.width )


    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                autoSkip: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 100,
                suggestedMin: 0,
                callback(tick) {
                  if (tick === 0) {
                    return tick;
                  }
                  // Format the amounts using Ks for thousands.
                  return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                }
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: false
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        }
      },
      ...this.props.chartOptions
    };

    const ConversionChart = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: this.props.chartData,
      options: chartOptions,
    });

    try {
      const buoMeta = ConversionChart.getDatasetMeta(0);
      buoMeta.data[0]._model.radius = 0;
      buoMeta.data[this.props.chartData.datasets[0].data.length - 1]._model.radius = 0;
    } catch (e) {}

    // Render the chart.
    ConversionChart.render();
  }

  render() {
    const { title } = this.props;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <canvas height="120" ref={this.canvasRef} style={{ maxWidth: "100% !important" }} />
        </CardBody>
      </Card>
    );
  }
}

ProductOverview.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

ProductOverview.defaultProps = {
  title: "Products Overview"
};

const mapPropsToState = ({ dashboard: { timeSeriesData } }) => {
  return {
    timeSeriesData
  }
};
export default connect(mapPropsToState)(ProductOverview);
