import { combineReducers } from "redux";
import auth from "./auth";
import loading from "./loading";
import transactions from "./transactions";
import airtimes from "./airtimes";
import dashboard from "./dashboard";
import wallet from './wallet'
import profile from './profile'

export default combineReducers({
  auth,
  loading,
  wallet,
  profile,
  airtimes,
  dashboard,
  transactions
});
