import { dateYmd } from "../../utils/helpers";
import { subDays } from "date-fns";
import { SET_WALLET, SET_WALLET_FUND_HISTORIES, SET_WALLET_FUND_HISTORIES_END_DATE, SET_WALLET_FUND_HISTORIES_START_DATE } from "../actions/wallet";

const initialState = {
  walletFundList: [],
  startDate: dateYmd(subDays(new Date(), 20)),
  endDate: dateYmd(new Date())
};

export default function walletReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WALLET:
      return { ...state, ...action.wallet };
    case SET_WALLET_FUND_HISTORIES:
      return { ...state, walletFundList: action.walletFundHistories };
      case SET_WALLET_FUND_HISTORIES_START_DATE:
      return { ...state, startDate: action.startDate };
    case SET_WALLET_FUND_HISTORIES_END_DATE:
      return { ...state, endDate: action.endDate };
    default:
      return state;
  }
}
