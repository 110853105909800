import { subDays } from "date-fns";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormInput, FormSelect, Row, Button } from "shards-react";
import { useInput } from "../../hooks/useInput";
import {
  handleFetchTransactions,
  setTransactionsEndDate,
  setTransactionsPerPage,
  setTransactionsStartDate
} from "../../redux/actions/transactions";
import { dateYmd, naira, transactionDateFormat } from "../../utils/helpers";
import TableList from "../../views/TableList";
import RangeDatePicker from "../common/RangeDatePicker";
import Status from "../partials/Status";
import options from "../partials/transactionOptions";

const headers = ["Customer", "Amount", "Reference", "Time", "Status"];

const renderRow = item => {
  return (
    <>
      <td>{item.customer}</td>
      <td>{naira(item.amount)}</td>
      <td>{item.partnerReferenceId}</td>
      <td>{transactionDateFormat(item.completedOn)}</td>
      <td>{<Status value={item.statusDescription || ""} />}</td>
    </>
  );
};

const TableFilters = ({filterState}) => {
  const {startDate, endDate} = useSelector(state => state.transactions)
  const [transRefprops, resetTransRef] = useInput("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  const partnerReferenceId = transRefprops.value;


  const handleStartDate = value => {
    dispatch(setTransactionsStartDate(dateYmd(value)));
  };

  const handleEndDate = value => {
    dispatch(setTransactionsEndDate(dateYmd(value)));
  };

  useEffect(() => {
    filterState({ partnerReferenceId, status });
  }, [partnerReferenceId, status]);

  const handleTansactionsFilters = () => {
    const partnerReferenceId = transRefprops.value;

    dispatch(handleFetchTransactions({ partnerReferenceId, status: Number(status) || undefined }));
  };


  const  resetFilters = () => {
    resetTransRef()
    setStatus("")
    dispatch(setTransactionsStartDate(dateYmd(subDays(new Date(), 20))));
    dispatch(setTransactionsEndDate(dateYmd(new Date())));
    dispatch(handleFetchTransactions({ partnerReferenceId, status: undefined }));
  }

  

  return (
    <Row className="transaction-filters">
       <Col sm="2">
          <FormSelect size="md"  onChange={(evt) => { 
            dispatch(setTransactionsPerPage(evt.target.value))
            handleTansactionsFilters()
           }}>
            <option value="">No. of Records</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </FormSelect>
        </Col>
      <Col md="3">
        <FormInput placeholder="Reference" {...transRefprops} />
      </Col>
      <Col sm="2">
        <FormSelect size="md" value={status} onChange={evt => setStatus(evt.target.value)}>
          <option>All Status</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </FormSelect>
      </Col>
      <Col sm="3">
        <RangeDatePicker
          startDateValue={handleStartDate}
          endDateValue={handleEndDate}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          size="md"
        />
      </Col>
      <Col sm="2" className="d-flex">
        <Button onClick={handleTansactionsFilters} size="md" theme="primary" className="btn-sm-block mr-2">
          Filter
        </Button>
        <Button onClick={resetFilters} size="md" theme="primary" className="btn-sm-block">
          <i class="large material-icons">cached</i>
          </Button>
      </Col>
    </Row>
  );
};

const TransactionListTable = () => {
  const { data, perPage, totalCount } = useSelector(state => state.transactions);
  const dispatch = useDispatch()

  const [filters, setFilters] = useState({});
  
  const getFilterState = filterState => {
    setFilters({ ...filters, ...filterState });
  };

  const getPageRecords = page => {
    const { partnerReferenceId, status } = filters;
    dispatch(handleFetchTransactions({ partnerReferenceId, status: Number(status) || undefined, page }))
  };

  return (
    <TableList
      headers={headers}
      title={<TableFilters filterState={getFilterState} />}
      data={data}
      renderItem={renderRow}
      pageSize={perPage}
      totalRecords={totalCount}
      fetchPageRecords={page => getPageRecords(page)}
    />
  );
};

export default TransactionListTable;
