import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, FormGroup, FormInput, Button, FormFeedback } from "shards-react";
import { handleLogin } from "../redux/actions/auth";
import { useInput } from "../hooks/useInput";
import { Link, useHistory } from "react-router-dom";
import { loginSchema } from "../validations/loginValidation";
import { yupErrorReducers } from "../utils/helpers";
import { useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";

const Login = () => {
  const { loading: loadingState } = useSelector(state => state.loading);
  const dispatch = useDispatch();
  const [userNameProps, resetUsername] = useInput("");
  const [passwordProps, resetPassword] = useInput("");
  const [errors, setError] = useState({});
  const history = useHistory();

  const handleSubmit = async e => {
    e.preventDefault();
    const userName = userNameProps.value;
    const password = passwordProps.value;
    loginSchema
      .validate({ userName, password }, { abortEarly: false })
      .then(formData => {
        dispatch(handleLogin(formData, history));
      })
      .catch(error => {
        const errs = yupErrorReducers(error.inner);
        setError(errs);
      });
  };

  return (
    <React.Fragment>
      <Loading loading={loadingState} background="#b3b1b380" loaderColor="#e73d05" />
      <div className="login-card">
        <Form className="login-form" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <div className="row justify-content-center align-items-center py-2 pb-4">
                <img src={require("../images/tingtel/tingtel-logo.png")} width="40" alt={"Tingtel logo"} />
                <div className="px-2 h5 pb-0 mb-0">Tingtel Dashboard</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <FormGroup>
                <label htmlFor="feEmailAddress">Email</label>
                <FormInput placeholder="Enter Username or Email" invalid={!!errors.userName} {...userNameProps} />
                <FormFeedback>{errors.userName}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <FormGroup>
                <label htmlFor="feEmailAddress">Password</label>
                <FormInput
                  placeholder="Enter Password"
                  type="password"
                  invalid={!!errors.password}
                  {...passwordProps}
                />
                <FormFeedback>{errors.password}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup className="mb-0 mt-3">
            <Button theme="accent" type="submit" className="btn-block">
              Login
            </Button>
          </FormGroup>
          <FormGroup className="mb-0 mt-3 d-flex justify-content-center">
            <Link to="/forgot-password">
              Forgot Password?
            </Link>
          </FormGroup>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default Login;
