import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "shards-react";
import WalletListTable from "../components/Tables/WalletListTable";
import { handleFetchWallet } from "../redux/actions/wallet";
import Loading from "react-fullscreen-loading";
import { naira } from "../utils/helpers";
import { Button } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import CsvDownload from "../components/partials/CsvDownload";
import { walletCsvHear } from "../utils/csv-headers";

const Wallets = () => {
  const dispatch = useDispatch();
  const { balance, creditLimit, walletFundList } = useSelector(state => console.log(state) || state.wallet);
  const { loading: loadingState } = useSelector(state => state.loading);

  useEffect(() => {
    dispatch(handleFetchWallet());
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Loading loading={loadingState} background="#b3b1b380" loaderColor="#e73d05" />
      <Row noGutters className="page-header py-4">
        <Col xs="6">
          <PageTitle title="Wallet" subtitle="Overview" className="text-sm-left mb-3" />
        </Col>
        <Col xs="6" className="d-flex align-items-center justify-content-end">
          <Button sm="3" className="ml-2">
            <CsvDownload csvData={{ data: walletFundList, headers: walletCsvHear, filename: "wallet-history.csv" }} />
          </Button>
        </Col>
      </Row>
      <div className="stats-container">
        <div className="stat">
          <p>Balance</p>
          <span>{naira(balance)}</span>
        </div>
        <div className="stat">
          <p>Available balance</p>
          <span>{naira(balance)}</span>
        </div>
        <div className="stat">
          <p>Credit Limit</p>
          <span>{naira(creditLimit)}</span>
        </div>
      </div>

      <Row>
        <Col sm="12" className="mb-4">
          <WalletListTable />
        </Col>
      </Row>
    </Container>
  );
};

export default Wallets;
