export const airtimeCsvHeaders = [
  { label: "Phone Number", key: "recipientPhoneNumber" },
  { label: "Network", key: "recipientNetwork" },
  { label: "Amount", key: "amount" },
  { label: "Time", key: "initiatedOn" },
  { label: "Status", key: "status" }
];


export const transactionsCsvheaders = [
    { label: "Customer", key: "customer" },
    { label: "Amount", key: "amount" },
    { label: "Reference", key: "partnerReferenceId" },
    { label: "Date", key: "completedOn" },
    { label: "Status", key: "statusDescription" }
]


export const walletCsvHear = [
  { label: "Amount", key: "amount" },
  { label: "Reference", key: "transactionReference" },
  { label: "Date", key: "transactionDate" }
]

