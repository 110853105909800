import { clientApi } from "../../axios";
import { loading } from "./loading";

export const SET_WALLET = "SET_WALLET";

export const SET_WALLET_FUND_HISTORIES = "SET_WALLET_FUND_HISTORIES";

export const SET_WALLET_FUND_HISTORIES_START_DATE = "SET_WALLET_FUND_HISTORIES_START_DATE";

export const SET_WALLET_FUND_HISTORIES_END_DATE = "SET_WALLET_FUND_HISTORIES_END_DATE";

export const setWalletDetails = wallet => ({
  type: SET_WALLET,
  wallet
});

export const setWalletFundHistories = walletFundHistories => ({
  type: SET_WALLET_FUND_HISTORIES,
  walletFundHistories
});

export const setWalletFundHistoriesStartDate = startDate => ({
  type: SET_WALLET_FUND_HISTORIES_START_DATE,
  startDate
});

export const setWalletFundHistoriesEndDate = endDate => ({
  type: SET_WALLET_FUND_HISTORIES_END_DATE,
  endDate
});

export function handleFetchWallet() {
  return async (dispatch, getState) => {
    dispatch(loading(true));
    try {
      const response = await clientApi.get("/api/partner/v1/wallets");
      dispatch(setWalletDetails(response.data.data[0]));
      const { walletId, startDate, endDate } = getState().wallet;
      const fundHistoryResponse = await handleWalletFundHistories({ walletId, startDate, endDate });
      dispatch(loading(false));
      dispatch(setWalletFundHistories(fundHistoryResponse.data.data));
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };
}

export function handleWalletFundHistories({ walletId, startDate, endDate }) {
  return clientApi.get(`/api/partner/v1/wallets/${walletId}/history?startDate=${startDate}&endDate=${endDate}`);
}
