import { clientApi } from "../../axios";
import { loading } from "./loading";
import {colors} from "../../components/custom/chartColors";

export const SET_DASHBOARD_SUMMARIES = "SET_DASHBOARD_SUMMARIES";

export const SET_DASHBOARD_PRODUCTS = "SET_DASHBOARD_PRODUCTS";

export const SET_DASHBOARD_START_DATE = "SET_DASHBOARD_START_DATE";

export const SET_DASHBOARD_END_DATE = "SET_DASHBOARD_END_DATE";

export const SET_TIMESERIES_DATA = "SET_TIMESERIES_DATA";

export const setDashboardSummary = summaries => ({
  type: SET_DASHBOARD_SUMMARIES,
  summaries
});

export const setDashboardProducts = products => ({
  type: SET_DASHBOARD_PRODUCTS,
  products
});

export const setDashboardStartDate = startDate => ({
  type: SET_DASHBOARD_START_DATE,
  startDate
});

export const setDashboardEndDate = endDate => ({
  type: SET_DASHBOARD_END_DATE,
  endDate
});

export const setTimeSeriesData = payload => ({
  type: SET_TIMESERIES_DATA,
  payload
});

function fetchDashboardSummary(startDate, endDate, partnerId) {
  return clientApi.get(
    `/api/Partners/dashboard/total/percent?StartDate=${startDate}&EndDate=${endDate}&PartnetId=${partnerId}`
  );
}

function fetchDashboardProducts(startDate, endDate, partnerId) {
  return clientApi.get(
    `/api/Partners/dashboard/product/percentage?StartDate=${startDate}&EndDate=${endDate}&PartnetId=${partnerId}`
  );
}

function fetchDashboardTimeseries(startDate, endDate, partnerId) {
  return clientApi.get(
    `/api/Partners/dashboard/timeseries?StartDate=${startDate}&EndDate=${endDate}&PartnetId=${partnerId}`
  );
}

function mapTimeSeriesData(datasets) {
  return datasets.map((d, i) => {
    return {
      data: d.data.length ? d.data : [0],
      label: d.label,
      ...colors[i]
    };
  });
}

export function handleDashboardData() {
  return (dispatch, getState) => {
    const { startDate, endDate } = getState().dashboard;
    const { partnerId } = getState().auth.currentUser;
    dispatch(loading(true));
    Promise.all([
      fetchDashboardSummary(startDate, endDate, partnerId),
      fetchDashboardProducts(startDate, endDate, partnerId),
      fetchDashboardTimeseries(startDate, endDate, partnerId)
    ])
      .then(([summaries, products, timeSeries]) => {
        dispatch(loading(false));
        dispatch(setDashboardSummary(summaries.data.data));
        dispatch(setDashboardProducts(products.data.data));
        const series = timeSeries.data.data.conversionModel.data;
        dispatch(setTimeSeriesData({ labels: series.labels, datasets: mapTimeSeriesData(series.datasets) }));
      })
      .catch(error => {
        dispatch(loading(false));
        console.log(error);
      });
  };
}
