import { SET_LOADING} from '../actions/loading'

export default function loadingReducer(state = false, action) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
