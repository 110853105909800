import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, FormGroup, FormInput, Button, FormFeedback } from "shards-react";
import {  handleResetPassword } from "../redux/actions/auth";
import { useInput } from "../hooks/useInput";
import { yupErrorReducers } from "../utils/helpers";
import { useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";
import { resetPasswordSchema } from "../validations/forgotPasswordValidations";
import { useHistory, useLocation } from "react-router";

const ResetPassword = () => {
  const { loading: loadingState } = useSelector(state => state.loading);
  const dispatch = useDispatch();
  const history = useHistory()

  const [newPasswordProps, resetPassword] = useInput("");
  const [confirmPasswordProps, resetConfirmPassword] = useInput("");
  const [errors, setError] = useState({});

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const handleSubmit = async e => {
    e.preventDefault();
    setError({});
    const newPassword = newPasswordProps.value;
    const confirmPassword = confirmPasswordProps.value;
    const userId = query.get("userId");
    const resetToken = query.get("token");
    

    resetPasswordSchema
      .validate({ newPassword, confirmPassword }, { abortEarly: false })
      .then(({newPassword}) => {
        dispatch(handleResetPassword({newPassword, userId, resetToken, dashboardIdentifier: 1}, history));
      })
      .catch(error => {
        const errs = yupErrorReducers(error.inner);
        setError(errs);
      });
  };

  return (
    <React.Fragment>
      <Loading loading={loadingState} background="#b3b1b380" loaderColor="#e73d05" />
      <div className="login-card">
        <Form className="login-form" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <div className="row justify-content-center align-items-center py-2 pb-4">
                <img src={require("../images/tingtel/tingtel-logo.png")} width="40" alt={"Tingtel logo"} />
                <div className="px-2 h5 pb-0 mb-0">Tingtel Dashboard</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <FormGroup>
                <label htmlFor="feEmailAddress">New Password</label>
                <FormInput
                  placeholder="Enter Password"
                  type="password"
                  invalid={!!errors.newPassword}
                  {...newPasswordProps}
                />
                <FormFeedback>{errors.newPassword}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <FormGroup>
                <label htmlFor="feEmailAddress">Confirm Password</label>
                <FormInput
                  placeholder="Enter Password"
                  type="password"
                  invalid={!!errors.confirmPassword}
                  {...confirmPasswordProps}
                />
                <FormFeedback>{errors.confirmPassword}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup className="mb-0 mt-3">
            <Button theme="accent" type="submit" className="btn-block">
              Submit
            </Button>
          </FormGroup>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
