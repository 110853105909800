import { format } from "date-fns";

export const yupErrorReducers = arr => {
  return arr.reduce((err, e) => {
    return { ...err, [e.path]: e.message };
  }, {});
};

export const toInitials = str => {
  return str
    .split(" ")
    .map(e => e.charAt(0).toUpperCase())
    .join("");
};

export const naira = number => {
  return Number(number || 0).toLocaleString("en-NG", {
    currency: "NGN",
    currencyDisplay: "symbol",
    style: "currency"
  });
};

export const dateYmd = value => format(value, "yyyy-MM-dd");

export const transactionDateFormat = value => format(new Date(value), "EEE, do MMM ''yy HH:mm:ss")

export const wait = (secs, fn ) => {
  setTimeout(() => {
     fn()
  }, secs)
}
