import React from "react";

const statusValue = {
  completed: "completed",
  failed: "failed",
  pending: "pending",
  processing: "processing",
  cancelled: "cancelled"
};

const Status = ({ value }) => {
  if (value.toLowerCase() === statusValue.completed) {
    return <span class="badge badge-success">{value}</span>;
  }
  if (value.toLowerCase() === statusValue.failed) {
    return <span class="badge badge-danger">{value}</span>;
  }
  if (value.toLowerCase() === statusValue.pending) {
    return <span class="badge badge-warning">{value}</span>;
  }
  if (value.toLowerCase() === statusValue.processing) {
    return <span class="badge badge-warning">{value}</span>;
  }
  if (value.toLowerCase() === statusValue.cancelled) {
    return <span class="badge badge-danger">{value}</span>;
  }

  return <span class="badge badge-secondary">{value}</span>;
};

export default Status;
