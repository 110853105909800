import { subDays } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormInput, Row, Button } from "shards-react";
import {
  handleFetchWallet,
  setWalletFundHistoriesEndDate,
  setWalletFundHistoriesStartDate
} from "../../redux/actions/wallet";
import { dateYmd, naira, transactionDateFormat } from "../../utils/helpers";
import TableList from "../../views/TableList";
import RangeDatePicker from "../common/RangeDatePicker";

const headers = ["Amount", "Reference", "Date"];

const renderRow = item => {
  return (
    <>
      <td>{naira(item.amount)} </td>
      <td>{item.transactionReference}</td>
      <td>{transactionDateFormat(item.transactionDate)}</td>
    </>
  );
};

const TableFilters = () => {
  const dispatch = useDispatch();
  const { walletId, startDate, endDate } = useSelector(state => state.wallet);

  const handleStartDate = value => {
    dispatch(setWalletFundHistoriesStartDate(dateYmd(value)));
  };

  const handleEndDate = value => {
    dispatch(setWalletFundHistoriesEndDate(dateYmd(value)));
  };

  const handleWalletsFundFilters = () => {
    dispatch(handleFetchWallet());
  };

  const  resetFilters = () => {
    dispatch(setWalletFundHistoriesStartDate(dateYmd(subDays(new Date(), 20))));
    dispatch(setWalletFundHistoriesEndDate(dateYmd(new Date())));
    dispatch(handleFetchWallet());
  }

  return (
    <Row className="transaction-filters">
      <Col md="6">
        <FormInput placeholder="Reference" onChange={() => {}} />
      </Col>

      <Col sm="3">
        <RangeDatePicker
          startDateValue={handleStartDate}
          endDateValue={handleEndDate}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          size="md"
        />
      </Col>
      <Col sm="3" className="d-flex">
        <Button size="md" onClick={handleWalletsFundFilters} theme="primary" className="btn-sm-block mr-2">
          Filter Wallets
        </Button>
        <Button onClick={resetFilters} size="md" theme="primary" className="btn-sm-block">
          <i class="large material-icons">cached</i>
          </Button>
      </Col>
    </Row>
  );
};

const WalletListTable = () => {
  const { walletFundList } = useSelector(state => state.wallet);
  return <TableList headers={headers} title={<TableFilters />} data={walletFundList} renderItem={renderRow} />;
};

export default WalletListTable;
