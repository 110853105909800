import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, Button, ListGroup, ListGroupItem, Progress } from "shards-react";
import { toInitials } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { handleFetchProfile } from "../../redux/actions/profile";

const UserDetails = ({ userDetails }) => {
  const dispatch = useDispatch()
  const { fullName, email } = useSelector(state => state.auth.currentUser);
  const company = useSelector(state => state.profile)

  useEffect(() => {
    dispatch(handleFetchProfile())
   }, [])
  return (
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          <span className="bg-dark rounded-circle text-white avatar user-profile mb-4">{toInitials(fullName)}</span>
        </div>
        <h4 className="mt-4">{fullName}</h4>
        <span className="text-muted d-block mb-2">{email}</span>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-4 text-center">
          <strong className="text-muted d-block mb-2">Company</strong>
          <span>{company.name}</span>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};

UserDetails.defaultProps = {
  userDetails: {
    name: "Sierra Brooks",
    avatar: require("./../../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
  }
};

export default UserDetails;
