import { clientApi } from "../../axios";
import { loading } from "./loading";

export const SET_PROFILE = "SET_PROFILE";

export const setProfileDetails = profile => ({
  type: SET_PROFILE,
  profile
});

export function handleFetchProfile() {
  return dispatch => {
    dispatch(loading(true));
    clientApi
      .get("/api/Partners/detail")
      .then(res => {
        dispatch(loading(false));
        dispatch(setProfileDetails(res.data.data));
      })
      .catch(error => {
        dispatch(loading(false));
        console.log(error);
      });
  };
}


