import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Modal, ModalBody, ModalHeader } from "shards-react";
import { Row, Col, Form, FormInput, FormSelect, ModalFooter } from "shards-react";
import { buyAirtimeSchema } from "../../validations/buyAirtimeValidationForm";
import { useInput } from "../../hooks/useInput";
import { naira, yupErrorReducers } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { buyAirtime } from "../../redux/actions/airtimes";
import { useHistory } from "react-router";

const AirtimeForm = ({ phoneNumberProps, amountProps, errors }) => (
  <Form>
    <Row className="mb-3">
      <Col xs="12" sm="6" className="d-flex align-items-center">
        <label htmlFor="feEmailAddress">Phone Number</label>
      </Col>
      <Col xs="12" sm="6">
        <FormInput
          placeholder="Enter Phone Number"
          type="number"
          {...phoneNumberProps}
          invalid={!!errors.recipientPhoneNumber}
        />
        <FormFeedback>{errors.recipientPhoneNumber}</FormFeedback>
      </Col>
    </Row>
    <Row className="mb-3">
      <Col xs="12" sm="6" className="d-flex align-items-center">
        <label htmlFor="feEmailAddress">Amount</label>
      </Col>
      <Col xs="12" sm="6">
        <FormInput placeholder="Enter Amount" type="number" {...amountProps} invalid={!!errors.amount} />
        <FormFeedback>{errors.amount}</FormFeedback>
      </Col>
    </Row>
  </Form>
);

const FormDetails = ({ amount, phoneNumber }) => (
  <div className="text-center">
    <p className="p-0 m-0">
      You are about to buy {naira(amount)} for {phoneNumber}
    </p>
    <p>Do you want to Continue ?</p>
  </div>
);

const modalStateProperties = {
  form: "form",
  details: "details"
};

const BuyAirtimePage = ({ openModal, closeButton }) => {
  const [modalState, setModalState] = useState(modalStateProperties.form);
  const [phoneNumberProps, resetPhoneNumber] = useInput("");
  const [amountProps, resetAmount] = useInput("");
  const [errors, setError] = useState({});
  const { walletId } = useSelector(state => state.wallet);
  const { loading } = useSelector(state => {
    return state.loading;
  });
  const [buttonName, setbuttonName] = useState("Buy Airtime");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (loading) {
      setbuttonName("Processing...");
      return;
    }
    setbuttonName("Yes, Buy Airtime");
  }, [loading]);

  const handleBuyAirtime = evt => {
    evt.preventDefault();
    setError({});
    if (loading) return;
    const recipientPhoneNumber = phoneNumberProps.value;
    const amount = amountProps.value;
    buyAirtimeSchema
      .validate({ recipientPhoneNumber, amount }, { abortEarly: false })
      .then(formData => {
        if (modalState === modalStateProperties.details) {
          dispatch(buyAirtime({ ...formData, walletId, history }));
        }
        setModalState(modalStateProperties.details);
      })
      .catch(error => {
        const errs = yupErrorReducers(error.inner);
        setError(errs);
      });
  };

  const resetModalContent = () => {
    setModalState(modalStateProperties.form)
    resetPhoneNumber()
    resetAmount()
    closeButton()
  }

  return (
    <Modal open={openModal}>
      <ModalHeader>Buy Airtime</ModalHeader>
      <ModalBody>
        {modalState === modalStateProperties.form ? (
          <AirtimeForm phoneNumberProps={phoneNumberProps} amountProps={amountProps} errors={errors} />
        ) : (
          <FormDetails amount={amountProps.value} phoneNumber={phoneNumberProps.value} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button outline size="md" onClick={resetModalContent} theme="primary">
          Cancel
        </Button>

        <Button size="md" disabled={loading} onClick={handleBuyAirtime} theme="primary">
          {buttonName}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BuyAirtimePage;
