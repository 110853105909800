import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import store from "../../store";
import jwt_decode from "jwt-decode";

function RouteGuard({ children, ...rest }) {
  const currentUser = store.getState().auth.currentUser;
  const expiry = store.getState().auth.expires
  const expiryTime = (Date.now() >= expiry * 1000)

  console.log(expiryTime)
  const history = useHistory();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (currentUser && !!currentUser.token && !expiryTime) {
          const passwordResetRequired = JSON.parse(
            jwt_decode(currentUser.token).RequirePasswordReset.toLowerCase()
          );
          if (passwordResetRequired) {
            return (
              <Redirect
                to={{
                  pathname: "/change-password",
                  referer: { from: location }
                }}
              />
            );
          }

          return children;
        }
        return (
          <Redirect
            to={{
              pathname: "/login",
              referer: { from: location }
            }}
          />
        );
      }}
    />
  );
}

export default RouteGuard;
