import React from "react";
import classNames from "classnames";
import { InputGroup, DatePicker, InputGroupAddon, InputGroupText } from "shards-react";

import "../../assets/range-date-picker.css";
import { dateYmd } from "../../utils/helpers";

class RangeDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(this.props.defaultStartDate) || new Date(),
      endDate: new Date(this.props.defaultEndDate) || new Date()
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

   handleStartDateChange(value) {
    this.setState({
      ...this.state,
      ...{ startDate: new Date(value) }
    });
  }

  handleEndDateChange(value) {
    this.setState({
      ...this.state,
      ...{ endDate: new Date(value) }
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.startDate !== this.state.startDate) {
      this.props.startDateValue(nextState.startDate);
    }

    if (nextState.endDate !== this.state.endDate) {
      this.props.endDateValue(nextState.endDate);
    }
  }

  render() {
    const { className } = this.props;
    const classes = classNames(className, "d-flex", "my-auto", "date-range");

    return (
      <InputGroup className={classes}>
        <DatePicker
          size="md"
          selected={this.state.startDate}
          onChange={this.handleStartDateChange}
          placeholderText="Start Date"
          dropdownMode="select"
          className="text-center"
          dateFormat="dd/MM/yyyy"
        />
        <DatePicker
          size="md"
          selected={this.state.endDate}
          onChange={this.handleEndDateChange}
          placeholderText="End Date"
          dropdownMode="select"
          className="text-center"
          dateFormat="dd/MM/yyyy"
        />
        <InputGroupAddon type="append">
          <InputGroupText>
            <i className="material-icons">&#xE916;</i>
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

export default RangeDatePicker;
