import React from 'react'
import { CSVLink } from "react-csv";

const CsvDownload = ({csvData}) => {
    return (
        <CSVLink {...csvData}>Export to CSV</CSVLink>    
    )
}


export default CsvDownload;