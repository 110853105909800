import React, {useEffect, useState} from 'react'
import RangeDatePicker from "../common/RangeDatePicker";
import { useInput } from "../../hooks/useInput";
import { Col, FormInput, FormSelect, Row, Button } from "shards-react";
import { handleFetchAirtimeTransactions, setAirtimeEndDate, setAirtimePageSize, setAirtimeStartDate } from "../../redux/actions/airtimes";
import { dateYmd } from "../../utils/helpers";
import { useDispatch, useSelector } from 'react-redux';
import { subDays } from 'date-fns';




const AirtimeTableFilters = ({ filterState }) => {
    const { partnerId } = useSelector(state => state.auth.currentUser);
    const { startDate, endDate } = useSelector(state => state.airtimes);
   // const [transRefprops, resetTransRef] = useInput("");
    const [phoneNumberprops, resetPhoneNumber] = useInput("");
    const [networkProvider, setNetworkProvider] = useState("");
    const [transactionStatus, setTransactionStatus] = useState("");
    const dispatch = useDispatch();
   // const transactionReference = transRefprops.value;
    const phoneNumber = phoneNumberprops.value;


    useEffect(() => {
      filterState({ phoneNumber, networkProvider, transactionStatus });
    }, [ phoneNumber, networkProvider, transactionStatus]);

    const handleStartDate = value => {
      dispatch(setAirtimeStartDate(dateYmd(value)));
    };

    const handleEndDate = value => {
      dispatch(setAirtimeEndDate(dateYmd(value)));
    };

    const handleAirtimeFilters = () => {
      dispatch(
        handleFetchAirtimeTransactions({
          partnerId,
          networkProvider,
          transactionStatus,
          phoneNumber,
          currentPage: 1
        })
      );
    };

  const  resetFilters = () => {
    resetPhoneNumber()
    setNetworkProvider("")
    setTransactionStatus("")
    dispatch(setAirtimeStartDate(dateYmd(subDays(new Date(), 20))));
    dispatch(setAirtimeEndDate(dateYmd(new Date())));
    dispatch(
      handleFetchAirtimeTransactions({
        partnerId,
        networkProvider: "",
        transactionStatus: "",
        phoneNumber: "",
        currentPage: 1
      })
    );
  }

    return (
      <Row className="transaction-filters">
         <Col sm="1">
          <FormSelect size="md" onChange={(evt) => {
            if(!evt.target.value) return
            dispatch(setAirtimePageSize(evt.target.value))
            handleAirtimeFilters()
           }}>
            <option value="">Page Size</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </FormSelect>
        </Col>
        {/* <Col md="2">
          <FormInput placeholder="Reference" {...transRefprops} />
        </Col> */}
        <Col md="2">
          <FormInput placeholder="Phone" type="number" {...phoneNumberprops} />
        </Col>
        <Col sm="2">
          <FormSelect size="md" value={networkProvider} onChange={evt => setNetworkProvider(evt.target.value)}>
            <option value="">Network</option>
            <option value="MTN">MTN</option>
            <option value="Glo">Glo</option>
            <option value="Airtel">Airtel</option>
            <option value="9Mobile">9mobile</option>
          </FormSelect>
        </Col>
        <Col sm="2">
          <FormSelect size="md" value={transactionStatus} onChange={evt => setTransactionStatus(evt.target.value)}>
            <option value="">Status</option>
            <option value="Completed">Completed</option>
            <option value="Pending">Pending</option>
            <option value="Failed">Failed</option>
          </FormSelect>
        </Col>
        <Col sm="3">
          <RangeDatePicker
            key={startDate || endDate}
            startDateValue={handleStartDate}
            endDateValue={handleEndDate}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            size="md"
            className
          />
        </Col>
        <Col sm="2" className="d-flex">
          <Button onClick={handleAirtimeFilters} size="md" theme="primary" className="btn-sm-block mr-2">
            Filter
          </Button>
          <Button onClick={resetFilters} size="md" theme="primary" className="btn-sm-block">
          <i class="large material-icons">cached</i>
          </Button>
        </Col>
      </Row>
    );
  };

  export default AirtimeTableFilters;
