import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "shards-react";
import TransactionListTable from "../components/Tables/TransactionListTable";
import { handleFetchTransactions } from "../redux/actions/transactions";
import PageTitle from "./../components/common/PageTitle";
import Loading from "react-fullscreen-loading";
import CsvDownload from "../components/partials/CsvDownload";
import { transactionsCsvheaders } from "../utils/csv-headers";

const Transactions = () => {
  const dispatch = useDispatch();
  const { loading: loadingState } = useSelector(state => state.loading);
  const { startDate, endDate, data } = useSelector(state => state.transactions);

  useEffect(() => {
    dispatch(handleFetchTransactions({ startDate, endDate }));
  }, []);
  return (
    <Container fluid className="main-content-container px-4">
      <Loading loading={loadingState} background="#b3b1b380" loaderColor="#e73d05" />
      <Row noGutters className="page-header py-4">
        <Col xs="6">
          <PageTitle title="Transactions" subtitle="Overview" className="text-sm-left mb-3" />
        </Col>
        <Col xs="6" className="d-flex align-items-center justify-content-end">
          <Button sm="3" className="ml-2">
            <CsvDownload csvData={{ data: data, headers: transactionsCsvheaders, filename: "transactions.csv" }} />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col sm="12" className="mb-4">
          <TransactionListTable />
        </Col>
      </Row>
    </Container>
  );
};

export default Transactions;
