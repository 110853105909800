import { dateYmd } from "../../utils/helpers";
import {  subDays } from 'date-fns'
import { SET_AIRTIME_END_DATE, SET_AIRTIME_START_DATE, SET_AIRTIME_TRANSACTIONS, SET_AIRTIME_PER_PAGE } from "../actions/airtimes";

const initialState = {
  result: [],
  startDate: dateYmd(subDays(new Date(), 20)),
  endDate: dateYmd(new Date()),
  perPage: 10
}

export default function airtimeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AIRTIME_TRANSACTIONS:
      return {...state, ...action.airtimes }
    case SET_AIRTIME_START_DATE: 
      return {...state, startDate: action.startDate }  
    case SET_AIRTIME_END_DATE:
      return {...state, endDate: action.endDate }  
    case SET_AIRTIME_PER_PAGE:
      return {...state, perPage: action.perPage }  
    default:
      return state;
  }
}
