import React from "react";
import { Container, Row } from "shards-react";
import { Provider } from "react-redux";
import store from "../store";

const EmptyLayout = ({ children }) => {
  return (
    <Provider store={store}>
      <Container fluid className="main-content-container">
        <Row className="justify-content-center">{children}</Row>
      </Container>
    </Provider>
  );
};

export default EmptyLayout;
