import { backOffice } from "../../axios";
import { loading } from "./loading";

export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_TRANSACTIONS_START_DATE = "SET_TRANSACTIONS_START_DATE";
export const SET_TRANSACTIONS_END_DATE = "SET_TRANSACTIONS_END_DATE";
export const SET_TRANSACTIONS_PER_PAGE = "SET_TRANSACTIONS_PER_PAGE";

export const setTransactions = transactions => ({
  type: SET_TRANSACTIONS,
  transactions
});

export const setTransactionsStartDate = startDate => ({
  type: SET_TRANSACTIONS_START_DATE,
  startDate
});

export const setTransactionsEndDate = endDate => ({
  type: SET_TRANSACTIONS_END_DATE,
  endDate
});

export const setTransactionsPerPage = perPage => ({
  type: SET_TRANSACTIONS_PER_PAGE,
  perPage
})

export function handleFetchTransactions({partnerReferenceId,simTransactionReference, status = undefined, page = 1}) {
  return (dispatch, getState) => {
    const { startDate, endDate, perPage} = getState().transactions
    dispatch(loading(true))
    backOffice
      .post("/Partners/transactions", {
        status,
        partnerReferenceId,
        simTransactionReference,
        pageSize:Number(perPage),
        from: startDate,
        to: endDate,
        pageNo: page
      })
      .then(response => {
        dispatch(loading(false))
       dispatch(setTransactions(response.data))
      })
      .catch(error => {
        dispatch(loading(false))
        console.log(error.message)
      });
  };
}
