import React from "react";
import { useSelector } from "react-redux";
import { naira } from "../../utils/helpers";
import TableList from "../../views/TableList";

const headers = ["No.", "Product", "Count", "Value"];

const renderRow = (item, i) => {
  return (
    <>
      <td>{i + 1}</td>
      <td>{item.productName}</td>
      <td>{item.totalTransactionCount}</td>
      <td>{naira(item.totalAmount)}</td>
    </>
  );
};

const ProductSummaryTable = () => {
  const { products } = useSelector(state => state.dashboard);
  return (
    <TableList headers={headers} title={<h6 className="m-0">Products</h6>} data={products} renderItem={renderRow} />
  );
};

export default ProductSummaryTable;
