import { toast } from "react-toastify";
import { clientApi } from "../../axios";
import { wait } from "../../utils/helpers";
import { loading } from "./loading";

export const SET_AIRTIME_TRANSACTIONS = "SET_AIRTIME_TRANSACTIONS";

export const SET_AIRTIME_START_DATE = "SET_AIRTIME_START_DATE";

export const SET_AIRTIME_END_DATE = "SET_AIRTIME_END_DATE";

export const SET_AIRTIME_PER_PAGE = "SET_AIRTIME_PER_PAGE";

export const fetchAirtimeTransactions = airtimes => ({
  type: SET_AIRTIME_TRANSACTIONS,
  airtimes
});

export const setAirtimeStartDate = startDate => ({
  type: SET_AIRTIME_START_DATE,
  startDate
});

export const setAirtimeEndDate = endDate => ({
  type: SET_AIRTIME_END_DATE,
  endDate
});

export const setAirtimePageSize = perPage => ({
  type: SET_AIRTIME_PER_PAGE,
  perPage
})

export function buyAirtime({ recipientPhoneNumber, walletId, amount, history }) {
  return dispatch => {
    dispatch(loading(true));
    clientApi
      .post("/api/partner/v1/airtime", { recipientPhoneNumber, walletId, amount })
      .then(resposne => {
        if (resposne.data.status === "00") {
          dispatch(loading(false));
          toast.success("Transaction Successful!", {
            position: toast.POSITION.TOP_CENTER
          });
        } else {
          dispatch(loading(false));
          toast.error(resposne.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
        }
        wait(3000, () => {
          window.location.reload();
        })
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function handleFetchAirtimeTransactions({
  partnerId,
  currentPage = 1,
  networkProvider,
  transactionReference,
  phoneNumber,
  transactionStatus
}) {
  return (dispatch, getState) => {
    const { startDate, endDate, perPage } = getState().airtimes
    dispatch(loading(true));
    clientApi
      .post("/api/partnerwallet/v1/transactionshistory", {
        partnerId,
        networkProvider,
        currentPage,
        transactionReference,
        transactionStatus,
        phoneNumber,
        pageSize:perPage,
        startDate,
        endDate
      })
      .then(resposnse => {
        dispatch(loading(false));
        dispatch(fetchAirtimeTransactions(resposnse.data.data));
      })
      .catch(error => {
        dispatch(loading(false));
        console.log(error);
      });
  };
}
