import React from "react";
import { Route, Redirect } from "react-router-dom";
import store from "../../store";

function AuthGuard({ children, ...rest }) {
  const currentUser = store.getState().auth.currentUser;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (currentUser && !!currentUser.token) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: "/login",
              referer: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export default AuthGuard;
