import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  Button,
  FormFeedback,
} from "shards-react";
import { useInput } from "../hooks/useInput";
import { handleChangePassword } from "../redux/actions/auth";
import { yupErrorReducers } from "../utils/helpers";
import { changePasswordSchema } from "../validations/changePasswordValidations";
import Loading from "react-fullscreen-loading";

const ChangePassword = () => {
  const [currentPasswordProps, resetUsername] = useInput("");
  const [newPasswordProps, resetPassword] = useInput("");
  const [confirmPasswordProps, resetConfirmPassword] = useInput("");
  const [errors, setError] = useState({});
  const dispatch = useDispatch();
  const { loading: loadingState } = useSelector((state) => state.loading);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentPassword = currentPasswordProps.value;
    const newPassword = newPasswordProps.value;
    const confirmPassword = confirmPasswordProps.value;
    changePasswordSchema
      .validate(
        { currentPassword, newPassword, confirmPassword },
        { abortEarly: false }
      )
      .then(() =>
        dispatch(
          handleChangePassword({ currentPassword, newPassword }, history)
        )
      )
      .catch((error) => setError(yupErrorReducers(error.inner)));
  };

  return (
    <React.Fragment>
      <Loading
        loading={loadingState}
        background="#b3b1b380"
        loaderColor="#e73d05"
      />
      <div className="login-card">
        <Form className="login-form" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <div className="row justify-content-center align-items-center py-2 pb-4">
                <div className="px-2 h5 pb-0 mb-0">Change Password</div>
              </div>
              <div className="alert alert-info" role="alert">
                use the form below to change your password!
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <FormGroup>
                <label htmlFor="feEmailAddress">Current Password</label>
                <FormInput
                  placeholder="Enter Current Password"
                  type="password"
                  invalid={!!errors.currentPassword}
                  {...currentPasswordProps}
                />
                <FormFeedback>{errors.currentPassword}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <FormGroup>
                <label htmlFor="feEmailAddress">New Password</label>
                <FormInput
                  placeholder="Enter New Password"
                  type="password"
                  invalid={!!errors.newPassword}
                  {...newPasswordProps}
                />
                <FormFeedback>{errors.newPassword}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <FormGroup>
                <label htmlFor="feEmailAddress">Confirm Password</label>
                <FormInput
                  placeholder="Confirm Password"
                  type="password"
                  invalid={!!errors.confirmPassword}
                  {...confirmPasswordProps}
                />
                <FormFeedback>{errors.confirmPassword}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          {/* Create Draft */}
          <FormGroup className="mb-0 mt-3">
            <Button theme="accent" type="submit" className="btn-block">
              Submit
            </Button>
          </FormGroup>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
