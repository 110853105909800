import React from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import AccountDetails from "../components/partials/AccountDetails";
import Loading from "react-fullscreen-loading";
import { useSelector } from "react-redux";
const AccountProfile = () => {
  const { loading: loadingState } = useSelector(state => state.loading);
  return (
    <Container fluid className="main-content-container px-4">
         <Loading loading={loadingState} background="#b3b1b380" loaderColor="#e73d05" />
      <Row noGutters className="page-header py-4">
        <PageTitle title="Business Account Details" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      <Row>
        <Col lg="12">
          <AccountDetails title={"To update your account details contact your account mananger"} />
        </Col>
      </Row>
    </Container>
  );
};

export default AccountProfile;
