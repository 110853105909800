import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, NavItem, NavLink } from "shards-react";
import { logout } from "../../../../redux/actions/auth";
import { toInitials } from "../../../../utils/helpers";

class UserActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    const { currentUser, dispatch, history } = this.props;
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="px-3 d-flex align-items-center">
          <span className="bg-dark rounded-circle text-white d-flex align-items-center justify-content-center mr-2 avatar">
            {currentUser ? toInitials(currentUser.fullName) : "?"}
          </span>
          <span className="d-none d-md-inline-block">{currentUser ? currentUser.fullName : "?"}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} small open={this.state.visible}>
          <DropdownItem tag={Link} to="/user-profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem className="text-danger" onClick={() => dispatch(logout(history))}>
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
const mapPropsToState = ({ auth: { currentUser } }) => ({
  currentUser
});

export default connect(mapPropsToState)(withRouter(UserActions));
