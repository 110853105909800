import { dateYmd } from "../../utils/helpers";
import { subDays } from "date-fns";
import {
  SET_DASHBOARD_END_DATE,
  SET_DASHBOARD_PRODUCTS,
  SET_DASHBOARD_START_DATE,
  SET_DASHBOARD_SUMMARIES,
  SET_TIMESERIES_DATA
} from "../actions/dashboard";

const initialState = {
  summaries: {},
  products: [],
  timeSeriesData: {
    labels: [],
    datasets: []
  },
  startDate: dateYmd(subDays(new Date(), 7)),
  endDate: dateYmd(new Date())
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_SUMMARIES:
      return { ...state, summaries: action.summaries };
    case SET_DASHBOARD_PRODUCTS:
      return { ...state, products: action.products };
    case SET_DASHBOARD_START_DATE:
      return { ...state, startDate: action.startDate };
    case SET_DASHBOARD_END_DATE:
      return { ...state, endDate: action.endDate };
    case SET_TIMESERIES_DATA:
      const { labels, datasets } = action.payload;
      return { ...state, timeSeriesData: { labels, datasets } };
    default:
      return state;
  }
}
