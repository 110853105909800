import { subDays } from "date-fns";
import { dateYmd } from "../utils/helpers";
import { handleDashboardData, setDashboardEndDate, setDashboardStartDate } from "../redux/actions/dashboard";

export const dashboardRangeFilters =  (range, dispatch) => {
  switch (range) {
    case "today":
      dispatch(setDashboardStartDate(dateYmd(new Date())));
      dispatch(setDashboardEndDate(dateYmd(new Date())));
      dispatch(handleDashboardData());
      break;
    case "last-week":
      console.log("second");
      dispatch(setDashboardStartDate(dateYmd(subDays(new Date(), 7))));
      dispatch(setDashboardEndDate(dateYmd(new Date())));
      dispatch(handleDashboardData());
      break;
    case "last-month":
      dispatch(setDashboardStartDate(dateYmd(subDays(new Date(), 30))));
      dispatch(setDashboardEndDate(dateYmd(new Date())));
      dispatch(handleDashboardData());
      break;
    default:
      return;
  }
};
