import { dateYmd } from "../../utils/helpers";
import { subDays } from "date-fns";
import { SET_TRANSACTIONS, SET_TRANSACTIONS_END_DATE, SET_TRANSACTIONS_PER_PAGE, SET_TRANSACTIONS_START_DATE } from "../actions/transactions";

const initialState = {
  data:[],
  startDate: dateYmd(subDays(new Date(), 20)),
  endDate: dateYmd(new Date()),
  perPage: 10
};

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return { ...state,  ...action.transactions };
    case SET_TRANSACTIONS_START_DATE:
      return { ...state, startDate: action.startDate };
    case SET_TRANSACTIONS_END_DATE:
      return { ...state, endDate: action.endDate };
    case SET_TRANSACTIONS_PER_PAGE:
      return {...state, perPage: action.perPage }  
    default:
      return state;
  }
}
